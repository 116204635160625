const mapAddresses = {
  methods: {
    mapAddressBusinessToProfile(address) {
      return {
        address: address?.address,
        address_line_1: address?.street,
        address_line_2: address?.house,
        address_line_zip: address?.zip,
        address_line_city: address?.city,
        country: address?.country,
        country_code: address?.country_code,
        place_id: address?.place_id,
      };
    },
    mapAddressProfileToBusiness(address) {
      return {
        address: address?.address,
        street: address?.address_line_1,
        house: address?.address_line_2,
        zip: address?.address_line_zip,
        city: address?.address_line_city,
        country: address?.country,
        country_code: address?.country_code,
        place_id: address?.place_id,
      };
    },
  },
};

export default mapAddresses;
