<template>
  <div>
    <div class="row no-gutters">
      <div :class="showAddressFields ? 'col-7 pr-3' : 'col-12'">
        <places-component
          :show-only-street="true"
          class="mt-2 mb-0"
          :searchCountries="searchCountries"
          :bounds="bounds"
          ref="places"
          :placeholder="addressPlaceholder"
          :street-placeholder="streetPlaceholder"
          :fulladdress="fulladdress"
          :searchTypes="searchTypes"
          @change-address="updateAddress"
          @change-invalid-value="updateInvalidValue"
          @change-invalid-street-value="updateInvalidStreetValue"
          @change-invalid-country="updateInvalidCountry"
          @change-search-error="updateSearchErrorValue"
          @change-select-open="updateAddressSelectionOpen"
        />
        <div v-if="(invalidStreet || searchError)
          && (!invalidCountry)" class="small text-primary mt-2 mb-3 mx-3">
          {{ addressErrorText }}
        </div>
        <div v-if="invalidCountry
          && !invalidStreet && !searchError" class="small text-primary mt-2 mb-3 mx-3">
          {{ countryErrorTextFormatted }}
        </div>
      </div>
      <div class="col-5">
        <div v-if="showAddressFields"
             :class="{
              'wet-input-error': $v.houseNo.$invalid,
             }"
             class="wet-input wet-input-with-label mt-2 mb-0"
        >
          <input
            id="wet-input-reg-house_no"
            :placeholder="houseNoPlaceholder"
            type="text"
            v-model="$v.houseNo.$model"
            :disabled="!invalid && !searchError"
            @change="updateHouseNo()"
          >
          <label for="wet-input-reg-house_no">
            {{ houseNoPlaceholder }}
          </label>
        </div>
        <div v-if="$v.houseNo.$error
          && showAddressFields" class="small text-primary mt-2 mb-3 mx-3">
          {{ houseNoErrorText }}
        </div>
      </div>
    </div>
    <div v-if="showAddressFields" class="row no-gutters mt-3">
      <div class="col-5 pr-3">
        <div
          :class="{
            'wet-input-error': $v.zip.$invalid,
          }"
          class="wet-input wet-input-with-label has-addons wet-input-disabled"
        >
          <input
            id="wet-input-reg-zip"
            :placeholder="zipPlaceholder"
            type="text"
            v-model="$v.zip.$model"
            :disabled="!invalid && !searchError"
            @change="updateZip()"
          >
          <label for="wet-input-reg-zip">
            {{ zipPlaceholder }}
          </label>
        </div>
        <div v-if="$v.zip.$error
          && showAddressFields" class="small text-primary mt-2 mb-3 mx-3">
          {{ zipErrorText }}
        </div>
      </div>
      <div class="col-7">
        <div
          :class="{
            'wet-input-error': $v.city.$invalid,
          }"
          class="wet-input wet-input-with-label has-addons"
        >
          <input
            id="wet-input-reg-city"
            :disabled="!invalid && !searchError"
            :placeholder="cityPlaceholder"
            type="text"
            v-model="$v.city.$model"
            @change="updateCity()"
          >
          <label for="wet-input-reg-city">
            {{cityPlaceholder}}
          </label>
        </div>
        <div v-if="$v.city.$error
          && showAddressFields" class="small text-primary mt-2 mb-3 mx-3">
          {{ cityErrorText }}
        </div>
      </div>
      <div class="col-12">
        <div
          class="nice-select"
          id="wet-input-country-select"
          :class="{
            'open': countrySelectOpen,
            'no-arrow': !this.invalid,
            'disabled': !this.invalid
          }"
          @click="openCountrySelect"
          style="min-width:auto;"
        >
        <label for="wet-input-country-select">
          {{countryPlaceholder}}
        </label>
      <span class="current">
        {{country}}
      </span>
          <ul class="list">
            <li
              class="option"
              :id="`country-option-${id}`"
              :class="(country || '').toString() === item ? 'selected focus' : ''"
              v-for="(item, id) in availableCountries"
              :key="id"
              @click="updateCountryValue(item, id);"
            >{{item}}</li>
          </ul>
        </div>
<!--          <input-->
<!--            id="wet-input-reg-country"-->
<!--            :disabled="true"-->
<!--            :placeholder="countryPlaceholder"-->
<!--            type="text"-->
<!--            :value="address.country"-->
<!--          >-->
      </div>
    </div>
  </div>
</template>

<script>
import inputMixin from '@/mixins/ui/inputMixin';
import placesComponent from '@/components/placesComponent.vue';
import LogService from '@/services/LogService';
import common from '@/mixins/common';
import { houseNo, zip, city } from '@/common/validations';
import countryNames from '@/enums/countries';
import mapAddresses from '@/mixins/mapAddresses';

export default {
  mixins: [inputMixin, common, mapAddresses],
  components: {
    placesComponent,
  },
  props: {
    houseNoPlaceholder: {
      type: String,
      required: true,
    },
    addressPlaceholder: {
      type: String,
      required: true,
    },
    streetPlaceholder: {
      type: String,
      required: true,
    },
    searchCountries: {
      type: Array,
      default: () => ['DE'],
    },
    bounds: {
      type: Object,
      default: () => undefined,
    },
    fulladdress: {
      type: Boolean,
      default: false,
    },
    searchTypes: {
      type: Array,
      default: () => [],
    },
    /** required address component for validation */
    requiredComponents: {
      type: Array,
      default: () => [],
    },
    addressErrorText: {
      type: String,
      required: true,
    },
    houseNoErrorText: {
      type: String,
      required: true,
    },
    zipErrorText: {
      type: String,
      required: true,
    },
    cityErrorText: {
      type: String,
      required: true,
    },
    countryErrorText: {
      type: String,
    },
    zipPlaceholder: {
      type: String,
      required: true,
    },
    cityPlaceholder: {
      type: String,
      required: true,
    },
    countryPlaceholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      availableCountries: ['Germany'],
      availableCountryCodes: ['DE'],
      countrySelectOpen: false,
      address: undefined,
      invalid: false,
      invalidStreet: false,
      invalidCountry: false,
      searchError: false,
      zip: undefined,
      city: undefined,
      houseNo: undefined,
      country: undefined,
      countryCode: undefined,
      addressSelectionOpen: false,
    };
  },
  computed: {
    countryErrorTextFormatted() {
      return this.format(this.countryErrorText, this?.country);
    },
    propsNoValue() {
      const props = { ...this.$props };
      delete props.value;
      return props;
    },
    showAddressFields() {
      LogService.log('showAddressFields', this.address !== undefined);
      return this.address !== undefined && this?.address !== ''
        && !this.invalidCountry && !this.addressSelectionOpen;
    },
  },
  watch: {
    searchCountries(newValue) {
      this.updateSearchCountries(newValue);
    },
  },
  methods: {
    updateSearchCountries(newValue) {
      let countriesObject = Object.keys(countryNames[this.lang])
        .filter((key) => newValue.includes(key)).reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
          obj[key] = countryNames[this.lang][key];
          return obj;
        }, {});
      countriesObject = Object.entries(countriesObject);
      LogService.log('countriesObject', countriesObject);
      const sortedCountriesObject = countriesObject.sort((a, b) => {
        if (this.mapUmlautsForCountryName(a[1]) < this.mapUmlautsForCountryName(b[1])) return -1;
        if (this.mapUmlautsForCountryName(a[1]) > this.mapUmlautsForCountryName(b[1])) return 1;
        return 0;
      });
      LogService.log('countriesObject', sortedCountriesObject);
      this.availableCountries = countriesObject.map((value) => value[1]);
      this.availableCountryCodes = countriesObject.map((value) => value[0]);
      LogService.log('availableCountries', this.availableCountries);
      LogService.log('availableCountryCodes', this.availableCountryCodes);
    },
    updateZip() {
      LogService.log('placestext, zip changed:', this.zip);
      this.$emit('change-zip-value', this.zip);
    },
    updateCity() {
      LogService.log('placestext, city changed:', this.city);
      this.$emit('change-city-value', this.city);
    },
    updateHouseNo() {
      LogService.log('placestext, houseNo changed:', this.houseNo);
      this.$emit('change-house-no-value', this.houseNo);
    },
    updateCountryValue(countryName, countryId) {
      this.country = countryName;
      this.countryCode = this.availableCountryCodes[countryId];
      LogService.log('placestext, country changed:', this.country);
      this.$emit('change-country-value', this.country);
      LogService.log('placestext, country code changed:', this.countryCode);
      this.$emit('change-country-code-value', this.countryCode);
    },
    mapUmlautsForCountryName(word) {
      if (word.charAt(0) === 'Ö') {
        return word.replace('Ö', 'O');
      }
      if (word.charAt(0) === 'Ä') {
        return word.replace('Ä', 'A');
      }
      if (word.charAt(0) === 'Ü') {
        return word.replace('Ü', 'U');
      }
      return word;
    },
    openCountrySelect() {
      if (this.invalid) {
        this.countrySelectOpen = !this.countrySelectOpen;
      }
    },
    updateAddressSelectionOpen(value) {
      this.addressSelectionOpen = value;
    },
    updateInitialAddress(address) {
      this.updateAddress(address);
      this.invalid = address.place_id === undefined;
      this.$refs.places.search = address.street;
    },
    updateAddress(address) {
      this.address = address.street;
      LogService.log('address changed:', address);
      this.$emit('change-address', address);
      this.zip = address.zip;
      this.city = address.city;
      this.houseNo = address.house;
      this.country = address.country;
      this.countryCode = address.country_code;
      this.$v.$touch();
    },
    updateInvalidValue(invalidValue) {
      this.invalid = invalidValue;
      this.$emit('change-invalid-value', this.invalid);
    },
    updateInvalidStreetValue(invalidStreetValue) {
      this.invalidStreet = invalidStreetValue;
      this.$emit('change-invalid-street-value', this.invalidStreet);
    },
    updateInvalidCountry(invalidCountry) {
      this.invalidCountry = invalidCountry;
      this.$emit('change-invalid-country', this.invalidCountry);
    },
    updateSearchErrorValue(searchErrorValue) {
      this.searchError = searchErrorValue;
    },
  },
  validations() {
    return { houseNo, zip, city };
  },
};
</script>
<style>
</style>
