const countryNames = {
  en: {
    AT: 'Austria',
    BE: 'Belgium',
    BG: 'Bulgaria',
    CH: 'Switzerland',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DK: 'Denmark',
    EE: 'Estonia',
    ES: 'Spain',
    FI: 'Finland',
    FR: 'France',
    GB: 'United Kingdom',
    GR: 'Greece',
    HR: 'Croatia',
    HU: 'Hungary',
    IE: 'Ireland',
    IS: 'Iceland',
    IT: 'Italy',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    MT: 'Malta',
    NL: 'Netherlands',
    NO: 'Norway',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    SE: 'Sweden',
    SI: 'Slovenia',
    SK: 'Slovakia',
    TR: 'Turkey',
  },
  pl: {
    AT: 'Austria',
    BE: 'Belgium',
    BG: 'Bulgaria',
    CH: 'Switzerland',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DE: 'Germany',
    DK: 'Denmark',
    EE: 'Estonia',
    ES: 'Spain',
    FI: 'Finland',
    FR: 'France',
    GB: 'United Kingdom',
    GR: 'Greece',
    HR: 'Croatia',
    HU: 'Hungary',
    IE: 'Ireland',
    IS: 'Iceland',
    IT: 'Italy',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    MT: 'Malta',
    NL: 'Netherlands',
    NO: 'Norway',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    SE: 'Sweden',
    SI: 'Slovenia',
    SK: 'Slovakia',
    TR: 'Turkey',
  },
  de: {
    AT: 'Österreich',
    BE: 'Belgien',
    BG: 'Bulgarien',
    CH: 'Schweiz',
    CY: 'Zypern',
    CZ: 'Tschechien',
    DE: 'Deutschland',
    DK: 'Dänemark',
    EE: 'Estland',
    ES: 'Spanien',
    FI: 'Finnland',
    FR: 'Frankreich',
    GB: 'Vereinigtes Königreich',
    GR: 'Griechenland',
    HR: 'Kroatien',
    HU: 'Ungarn',
    IE: 'Irland',
    IS: 'Island',
    IT: 'Italien',
    LI: 'Liechtenstein',
    LT: 'Litauen',
    LU: 'Luxemburg',
    LV: 'Lettland',
    MT: 'Malta',
    NL: 'Niederlande',
    NO: 'Norwegen',
    PL: 'Polen',
    PT: 'Portugal',
    RO: 'Rumänien',
    SE: 'Schweden',
    SI: 'Slowenien',
    SK: 'Slowakei',
    TR: 'Türkei',
  },
};

export default countryNames;
