<template>
  <div
    class="nice-select no-arrow wet-input wet-input-with-label"
    ref="place-select"
    :class="{
      'wet-input-error': this.showError,
      'open': selectOpen,
    }"
    style="min-width:auto;"
    tabindex="0"
    v-click-outside="clickOutside"
    @keyup.prevent.down="nextSelect(true)"
    @keyup.prevent.up="nextSelect(false)"
    @keyup.prevent.enter="change(selected)"
  >
    <input
      id="wet-input-address"
      :placeholder="placeholder"
      type="text"
      class="current w-100"
      v-model="$v.search.$model"
      @input="(e) => {
        searchHandler(e.target.value);
      }"
      @focus="selectOpen = true"
      ref="search"
      autocomplete="off"
    >
    <label v-if="showLabel" for="wet-input-address">
      {{showOnlyStreet && !selectOpen ? streetPlaceholder : placeholder}}
    </label>
    <ul class="list">
      <li
        :id="`wet-input-address-option-${index}`"
        class="option"
        :class="selected && selected.name === item.name ? 'selected focus' : ''"
        v-for="(item, index) in places"
        :key="index"
        @click="change(item), selected = item"
      >{{item.title}}</li>
    </ul>
  </div>
</template>
<script>
import places from '@/mixins/places';
import common from '@/mixins/common';
import { mapState } from 'vuex';
import LogService from '@/services/LogService';
import { notEmpty } from '@/common/validations';

export default {
  props: {
    searchCountries: {
      type: Array,
      default: () => [],
    },
    showOnlyStreet: {
      type: Boolean,
      default: false,
    },
    checkCountries: {
      type: Boolean,
      default: true,
    },
    fulladdress: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    searchTypes: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: true,
    },
    streetPlaceholder: {
      type: String,
      default: '',
    },
    bounds: {
      type: Object,
      default: () => undefined,
    },
  },
  mixins: [places, common],
  data() {
    return {
      address: {},
      invalid: false, /** true if any props of selected place is empty */
      invalidStreet: false,
      invalidCountry: false,
      selectOpen: false,
      selected: {},
      search: undefined, /** search input string */
    };
  },
  computed: {
    ...mapState(['strings']),
    places() {
      let pl = [];
      LogService.log('googleApis.predictions', this.googleApis.predictions);
      if (this.googleApis.predictions && this.googleApis.predictions.length > 0) {
        pl = this.googleApis.predictions.map((el) => ({
          title: el.description,
          name: el.place_id,
        }));
      }
      return pl;
    },
    showError() {
      return (this.showOnlyStreet ? this.invalidStreet : this.invalid)
        || this.invalidCountry || this.$v.search.$error;
    },
  },
  watch: {
    '$v.search.$error': {
      handler(val) {
        LogService.log('Places, $v.search.$error changed', val);
        this.$emit('change-search-error', val);
      },
      deep: true,
    },
    selectOpen(val) {
      if (val) {
        if (this.places?.length === 0) {
          this.searchHandler(this.search);
        }
      } else this.$refs.search.blur();
      this.$emit('change-select-open', this.selectOpen);
    },
    search(newValue) {
      LogService.log('Places, watch search', newValue);
    },
  },
  async mounted() {
    // load google map script & init places
    await this.loadMapScript();
    await this.initMapPlaces();
    this.$emit('auto-complete-service-ready');

    this.search = this.calcFullAddr(this.address);
  },
  methods: {
    async presetAddress(address, hotelName) {
      this.search = address;
      await this.searchHandler(this.search);
      LogService.log('places', this.places);
      if (this.places.length > 0) {
        await this.change(this.places[0], hotelName);
        LogService.log('places hotelName', hotelName);
        LogService.log('presetAddress', address);
        LogService.log('places', this.places);
        LogService.log('selected', this.selected);
      } else {
        this.search = undefined;
      }
    },
    calcFullAddr(addrObj) {
      if (this.showOnlyStreet) {
        return addrObj.street || addrObj.address;
      }
      /** convert address object to search string as view: 'Street House' */
      return [addrObj.street, addrObj.house].filter((el) => el && el.length > 0).join(' ')
        || addrObj.address;
    },
    async searchHandler(searchValue) {
      LogService.log('searchValue', searchValue);
      /** search place by string */
      // it for convert country string to ISO 3166-1 Alpha-2 country code
      const country = this.searchCountries.length > 0 && this.searchCountries.length < 6
        ? this.searchCountries
        : [];

      // types
      const types = this.searchTypes;

      // search places
      await this.searchPlaces(searchValue, country, types, this.bounds);

      // select first place
      const [place] = this.places;
      if (this.selectOpen || !this.selected?.name) this.selected = place;
      return place;
    },
    nextSelect(next) {
      if (!this.selectOpen) return;
      let to = 0;
      const current = this.places?.findIndex((el) => el.name === this.selected.name);
      if (next) {
        if (current + 1 < this.places.length) {
          to = current + 1;
        } else to = 0;
      } else if (current > 0) {
        to = current - 1;
      } else to = this.places.length - 1;

      this.selected = this.places[to];
    },
    async change(selected, differentNameToShow = null) {
      LogService.log('Places, change selected to', selected);
      this.selectOpen = false;
      const address = await this.phraseAddress(selected);
      // check if address has changed, if not, do nothing, so differentNameToShow is not overwritten
      if (this.address.address === address.address) {
        return;
      }
      LogService.log('Places, change address to', address);
      this.validateAddress(address);
      this.address = address;
      if (differentNameToShow !== null) {
        this.address.name = differentNameToShow;
        this.search = `${differentNameToShow}, ${this.address.city}`;
      } else {
        this.search = this.calcFullAddr(this.address);
      }
      this.$emit('change-address', this.address);
    },
    validateAddress(address) {
      LogService.log('Places, validateAddress, full address needed:', this.fulladdress);
      this.invalidStreet = !address.street;
      LogService.log('Places, validateAddress, invalid street value:', this.invalidStreet);
      this.$emit('change-invalid-street-value', this.invalidStreet);
      this.invalid = this.fulladdress ? !address.street || !address.house : !address.id;
      LogService.log('Places, validateAddress, invalid value:', this.invalid);
      this.$emit('change-invalid-value', this.invalid);
      LogService.log('Places, validateAddress, should country be checked:', this.checkCountries);
      if (this.checkCountries) {
        this.invalidCountry = address.street
          && !this.searchCountries.includes(address.country_code);
        LogService.log('Places, validateAddress, invalid country', this.invalidCountry);
        this.$emit('change-invalid-country', this.invalidCountry);
      }
    },
    async phraseAddress(selected) {
      /**
       * validate address by google api and return address object
       */
      LogService.log('Places, phraseAddress start, selected', selected);
      let address = {
        id: '',
        city: '',
        street: '',
        house: '',
        zip: '',
        country: '',
        country_code: '',
        addr: '',
      };

      // google api validate
      if (selected?.name) {
        LogService.log('Places, phraseAddress, selected.name', selected.name);
        const [addr] = await this.getAddress(selected?.name);
        address = this.gPlaceObjToAddr(addr);
        LogService.log('phrased address: ', address);
      }
      return address;
    },
    clickOutside() {
      if (this.selectOpen) {
        this.change(this.selected);
        this.selectOpen = false;
      }
    },
  },
  validations() {
    return { search: notEmpty };
  },
};
</script>
