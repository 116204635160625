import { mapState } from 'vuex';
import LogService from '@/services/LogService';

class Curve {
  // curve class
  constructor({
    start, end, map, curvature, color, id,
  }) {
    this.start = start;
    this.end = end;
    this.map = map;
    this.id = id;
    this.curvature = curvature || 0.2;
    this.color = color || '#03aaf5';
    this.create();
  }

  curveMarker = null;

  position = null;

  icon = null;

  calc() {
    const { Point } = window.google.maps;
    this.position = this.start.getPosition(); // latlng
    const pos2 = this.end.getPosition();
    const projection = this.map.getProjection();
    if (!projection) return;
    const p1 = projection.fromLatLngToPoint(this.position); // xy
    const p2 = projection.fromLatLngToPoint(pos2);
    // Calculate the arc.
    // To simplify the math, these points
    // are all relative to p1:
    const e = new Point(p2.x - p1.x, p2.y - p1.y); // endpoint (p2 relative to p1)
    const m = new Point(e.x / 2, e.y / 2); // midpoint
    const o = new Point(e.y, -e.x); // orthogonal
    const c = new Point( // curve control point
      m.x + this.curvature * o.x,
      m.y + this.curvature * o.y,
    );

    const path = `M 0,0 q ${c.x},${c.y} ${e.x},${e.y}`;

    const zoom = this.map.getZoom();
    const scale = 1 / (2 ** -zoom);

    this.icon = {
      path,
      scale,
      strokeWeight: 2,
      fillColor: 'none',
      strokeColor: this.color,
    };
  }

  #ev1;

  #ev2;

  #ev3;

  #ev4;

  #updateCallback = this.update.bind(this);

  create() {
    const { Marker } = window.google.maps;
    this.calc();
    this.curveMarker = new Marker({
      position: this.position,
      clickable: false,
      icon: this.icon,
      zIndex: -9999, // behind the other markers
      map: this.map,
    });
    this.#ev1 = this.map.addListener('projection_changed', this.#updateCallback);
    this.#ev2 = this.map.addListener('zoom_changed', this.#updateCallback);
    this.#ev3 = this.start.addListener('position_changed', this.#updateCallback);
    this.#ev4 = this.end.addListener('position_changed', this.#updateCallback);
  }

  update() {
    this.calc();
    this.curveMarker.setOptions({
      position: this.position,
      icon: this.icon,
    });
  }

  remove() {
    this.#ev1.remove();
    this.#ev2.remove();
    this.#ev3.remove();
    this.#ev4.remove();
    this.curveMarker.setMap(null);
  }
}
const places = {
  data() {
    return {
      googleApis: {
        API_KEY: process.env.VUE_APP_GAPI_KEY,
        autocompleteService: null,
        predictions: null,
        sessionToken: null,
        loading: false,
        map: null,
        maps: null,
        LatLng: { lat: 52.5069704, lng: 13.2846502 },
        directionsService: null,
        directionsRenderer: null,
        minZoomLevel: 8,
        maxZoom: 17,
        curves: [],
        geocoder: null,
        script: null, /** google maps script dom element */
      },
    };
  },
  computed: {
    ...mapState(['lang']),
  },
  methods: {
    async loadMapScript() {
      return new Promise((res) => {
        // MAP API
        if ('google' in window) return res();
        if (!('loadingGoogleEvent' in window)) {
          window.loadingGoogleEvent = new Event('googleLoaded');
          const mapScript = document.createElement('script');
          mapScript.type = 'text/javascript';
          mapScript.defer = true;
          LogService.log('places, langugage', this.lang);
          mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApis.API_KEY}&libraries=places,geometry&language=${this.lang}`;
          mapScript.onload = () => {
            document.dispatchEvent(window.loadingGoogleEvent);
          };
          document.body.appendChild(mapScript);
          this.googleApis.script = mapScript;
        }
        return document.addEventListener('googleLoaded', () => res());
      });
    },
    initMapPlaces() {
      // Create a new session token.
      this.$set(this.googleApis, 'sessionToken', new window.google.maps.places.AutocompleteSessionToken());

      // Pass the token to the autocomplete service.
      this.$set(this.googleApis, 'autocompleteService', new window.google.maps.places.AutocompleteService());
    },
    initMaps() {
      this.googleApis.maps = window.google.maps;
    },
    initMap(el, styles) {
      const map = new window.google.maps.Map(
        el,
        {
          zoom: 11,
          maxZoom: this.googleApis.maxZoom,
          center: this.googleApis.LatLng,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
          styles,
          clickableIcons: false,
        },
      );
      this.$set(this.googleApis, 'map', map);

      // event handling
      map.addListener('click', (event) => {
        this.$emit('map-click', event);
      });
    },
    destroyMap() {
      const { map } = this.googleApis;
      const { maps } = window.google || {};
      if (!map || !maps) return;

      // event handling
      maps.event.clearInstanceListeners(map);

      this.$delete(this.googleApis, 'map');
    },
    async searchPlaces(input, country, types, bounds) {
      /*
      @input: String,
      @country: String || Array<String>, string is ISO 3166-1 Alpha-2 country code, case insensitive
      */
      LogService.log('places.js, allowed countries:', country);
      return new Promise((res) => {
        let error = false;
        if (!input) {
          error = true;
        }
        if (!this.googleApis.autocompleteService) {
          error = true;
        }
        if (error) {
          this.$set(this.googleApis, 'predictions', []);
          res();
          return;
        }
        LogService.log('places bounds', bounds);
        this.googleApis.autocompleteService.getPlacePredictions(
          {
            input,
            sessionToken: this.googleApis.sessionToken,
            types: types || [],
            bounds,
            componentRestrictions: {
              country,
            },
          },
          (predictions, status) => {
            if (status === 'OK') {
              this.$set(this.googleApis, 'predictions', predictions);
            }
            res();
          },
        );
      });
    },
    async getAddress(placeId, location) {
      // convert place_id to address
      return new Promise((res) => {
        if (!this.googleApis.geocoder) {
          this.$set(this.googleApis, 'geocoder', new window.google.maps.Geocoder());
        }
        let address = {};
        this.googleApis.geocoder.geocode(
          { placeId, location, language: this.lang }, (results, status) => {
            if (status === 'OK') {
              address = results;
            }
            return res(address);
          },
        );
      });
    },
    gPlaceObjToAddr(addr) {
      // convert google place object to address
      const getAddr = (addrObj, type, short) => {
        const str = this.$lodash.find(addrObj.address_components,
          (el) => el.types.includes(type));
        return str ? str[short ? 'short_name' : 'long_name'] : '';
      };
      let city = getAddr(addr, 'locality')
        || getAddr(addr, 'administrative_area_level_3')
        || getAddr(addr, 'postal_town'); /** In the UK and in Sweden, */
      const street = getAddr(addr, 'route');
      const house = getAddr(addr, 'street_number');

      LogService.log('gPlaceObjToAddr', city, street, house, getAddr(addr, 'administrative_area_level_2'));
      if (city === '' && (street !== '' || house !== '')) {
        city = getAddr(addr, 'administrative_area_level_2');
      }

      const zip = getAddr(addr, 'postal_code');
      const country = getAddr(addr, 'country');
      const country_code = getAddr(addr, 'country', true);
      const id = addr.place_id;
      const address = addr.formatted_address;
      const latitude = addr.geometry.location.lat();
      const longitude = addr.geometry.location.lng();

      return {
        address,
        id,
        city,
        street,
        house,
        zip,
        country,
        country_code,
        latitude,
        longitude,
        place: addr,
      };
    },
    setMarker(position, { svg, title, draggable }) {
      // place marker on map and return it
      const marker = new window.google.maps.Marker({
        position,
        map: this.googleApis.map,
        title,
        icon: {
          anchor: new window.google.maps.Point(14, 14),
          url: `data:image/svg+xml;utf-8, ${svg}`,
        },
        draggable,
      });
      marker.addListener('dragend', this.onMarkerDragEnd);
      return marker;
    },
    isMarker(marker) {
      // check if is google marker
      return marker instanceof window.google.maps.Marker;
    },
    setMarkerPosition(marker, position) {
      // set marker pos
      marker.setPosition(position);
    },
    removeMarker(marker) {
      // remove marker from map
      marker.setMap(null);
    },
    setLine({ start, end, color }) {
      // set polyline
      const id = this.$uuid();
      const curve = new Curve({
        start,
        end,
        map: this.googleApis.map,
        id,
        color,
      });
      // this.$set(this.googleApis, 'curves', [...this.googleApis.curves, curve])
      this.googleApis.curves.push(curve);
      return curve;
    },
    delLine(curve) {
      curve.remove();
      this.$set(this.googleApis, 'curves', this.googleApis.curves?.filter((c) => c.id !== curve.id));
    },
    isLine(line) {
      // check if is line
      return line instanceof Curve;
    },
    setPos(latLng) {
      this.googleApis.map.panTo(latLng);
    },
    setZoom(zoom) {
      this.googleApis.map.setZoom(zoom);
    },
    zoom(markers) {
      // zoom to show all features in map on screen
      const bounds = this.calcBounds(markers);
      this.googleApis.map.fitBounds(bounds);
      const zoom = this.googleApis.map.getZoom();
      this.googleApis.map.setZoom(zoom - 1);
    },
    calcBounds(markers) {
      // calc bounds coords
      const bounds = new window.google.maps.LatLngBounds();
      markers.forEach((feature) => {
        const geometry = feature.getPosition();
        if (geometry instanceof window.google.maps.LatLng) {
          bounds.extend(geometry);
        }
      });
      return bounds;
    },
    getDistance(start, end) {
      // compute distance in meters between two markers
      let distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        start.getPosition(),
        end.getPosition(),
      );
      distance = Math.round(distance / 10) / 100;
      return distance;
    },
    onMarkerDragEnd(e) {
      this.$emit('marker-dragend', e);
    },
  },
  destroyed() {
    // destroy all curves
    this.googleApis.curves.forEach((curve) => {
      this.delLine(curve);
    });

    // destroy map
    this.destroyMap();
  },
};

export default places;
